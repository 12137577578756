.boat {
    flex-direction: row;
    position: relative; /* Necesario para que los hijos puedan usar posicionamiento absoluto */
    width: 100%;
    z-index: 0;
    cursor: pointer;
  }


  .boat-titulo-container{
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
  }
  
  .boat-titulo{
    font-size: 38px;
    letter-spacing: 0px;
    word-spacing: 0px;
    color: rgb(240, 240, 240);
    font-weight: 400;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: uppercase;
  }

  .boat-img-dias{
    position: absolute;
    height: auto;
    bottom: 1px;
    font-size: 20px;
    font-weight: 700;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: uppercase;
  }

  .boat-container-img {
    z-index: 2;
  }
  
  .boat-img {
    position: absolute;
    width: 70px;
    height: auto;
    bottom: 1px;
  }
  

  .boat-container {
    position: absolute;
    width: 70px;
    height: auto;
    bottom: 1px;
  }
  
  .tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 250px;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    background: #868686d0;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    transition: opacity 0.3s, visibility 0.3s, top 0.3s, background 0.3s;
    box-shadow: 0 2px 4px rgba(20, 20, 20, 0.2);
  }
  
  .tooltip::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #696969 transparent;
    transform: translateX(-50%);
  }
  
  .boat-container:hover .tooltip {
    top: 120%;
    opacity: 1;
    visibility: visible;
    background: #868686d0;
    transform: translate(-50%, -5px);
  }
  





  