.port {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 15%;
    background-color: #9c9c9c;
    -webkit-box-shadow: inset -1px 3px 19px -8px rgba(0,0,0,0.65);
    -moz-box-shadow: inset -1px 3px 19px -8px rgba(0,0,0,0.65);
    box-shadow: inset -1px 3px 19px -8px rgba(0,0,0,0.65);
    cursor: default;
}

.button {
    transform: rotate(-90deg); /* Rota el texto -90 grados */
    transform-origin: center center; /* Ajusta el origen de la rotación al centro */
    white-space: nowrap; /* Evita que el texto se divida en varias líneas */
    display: inline-block; /* Asegura que el contenedor se comporte como un bloque en línea */
    --border-right: 6px;
    --text-stroke-color: #ebebeb;
    --animation-color: #19273a;
    --fs-size: 6em;
    letter-spacing: 4px;
    text-decoration: none;
    font-size: var(--fs-size);
    font-family: "Arial";
    position: relative;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 4px var(--text-stroke-color);
    filter: drop-shadow(0 0 23px var(--animation-color));
}
/*
.hover-text {
    position: absolute;
    box-sizing: border-box;
    content: attr(data-text);
    color: var(--animation-color);
    width: 0%;
    inset: 0;
    border-right: var(--border-right) solid var(--animation-color);
    overflow: hidden;
    transition: 0.5s;
    -webkit-text-stroke: 1px var(--animation-color);
}
*/
/* hover */
/*
.button:hover .hover-text {
    width: 100%;
    filter: drop-shadow(0 0 23px var(--animation-color));
}
*/