.track {
    position: relative;
    width: 100%;
    height: 100vh; /* Ocupa toda la altura de la pantalla */
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Espacio entre los elementos, incluidos los barcos y la línea de tiempo */
    align-items: stretch;
    padding: 0;
    margin: 0;
    z-index: 0;
}

.track-container{
    z-index: 2;
}

.item.even, .item.odd {
    display: flex;
    align-items: center;
    width: 100%;
    flex: 1;
    margin: 0;
    box-shadow: inset -19px -200px 15px -185px rgba(5,5,5,0.12);

}

.item.even {
    background-color: #b4d0e5;
}

.item.odd {
    background-color: #00897B;
}

.line-time-container {
    flex-shrink: 0; /* No se encoja si hay poco espacio */
    height: 5px; /* Ajusta esta altura según necesites */
    width: 100%;
    background-color: #f3f3f3;

}
