.boatDetails {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  
  .boatDetails > div {
    background-color: rgb(241, 241, 241);
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .container-escape-btn-BD {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  
  .escape-btn-BD {
    background-color: rgba(0, 0, 0, 0.24);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 18px;
    z-index: 1000;
  }

  .boatDetails-titles-D {
    flex-grow: 1; /* Esto permitirá que el título ocupe todo el espacio disponible entre el inicio y el botón de escape */
    font-size: 40px;
    margin-left: 20px;

  }
  
  .boatDetails-item {
    display: flex;
    font-size: 20px;
  }
  
  .boatDetails-titles {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .boatDetails-data {
    font-weight: normal;
  }
  
  
  .boatDetails-item-super {
    display: flex;
    font-size: 25px;
  }
  