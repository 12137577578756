.bodega{
    display: relative;
    padding: 10px;
}

.nombre-barco-bodega{
    font-size: 30px;
    margin-bottom: 20px;
}

.bodega-container-each{
    display: relative;
    padding: 10px;
}