.App {
}

html, body {
  height: 100%;
  margin: 0; /* Elimina el margen predeterminado del body */
}

.container {
  display: flex;
  width: 100%;
}

.container > * {
  flex-grow: 1;
}

.container > .port {
  flex-basis: 10%;
}

.container > .track {
  flex-basis: 90%;
}


/* FullScreenButton.css */

.fullscreen-btn {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
}

.fullscreen-btn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
