.lineTime {    
    flex-direction: row;
    position: relative; /* Necesario para que los hijos puedan usar posicionamiento absoluto */
    width: 100%;
}

/* SimpleTimeline.css */
    .simple-timeline {
        position: relative;
    }
  .simple-timeline-item {
        z-index: 1; /* Controla el orden de apilamiento, valores más altos se superponen a los más bajos */
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
        font-size: 30px;
        color: #19273a;
    }
  
  .timeline-line {
    position: absolute;
    top: 75%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000000;
    transform: translateY(-50%);
  }
  
  .timeline-marker {
    position: absolute;
    top: 50%;
    width: 10px;
    height: 10px;
    background-color: #ff0000;
    border-radius: 50%;
    transform: translateY(-50%);
  }
  
  .timeline-data {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1em;
    color: #666;
  }
  